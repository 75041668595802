import React, { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import URLHelper from "../../common/URLHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useDebounce from "../../common/useDebounce";
import useUpdateUrlSearchParams from "../../common/useUpdateUrlSearchParams";
import InvoiceStatusSelect from "../ui/InvoiceStatusSelect";
import {
  STATUS_OPTIONS,
  TRANSFERED_OPTIONS
} from "../../common/InvoiceStatusHelper";
import ToggleableDateRangeFacet from "../ui/facet/ToggleableDateRangeFacet";
import { MarkAsTransferredSelect,CurrencyModal } from "./subcomponents";

const InvoiceSearch = (props) => {
  const {
    clubNameState,
    setClubName,
    pageCount,
    matchCount,
    buildFilter,
    invoiceList,
    refetchInvoices
  } = props;
  // url
  const history = useHistory();
  const urlParams = URLHelper.queryToState(history.location);
  const initCancellationDate = () => {
    let startDate = urlParams.cancellationDateFrom
      ? moment(urlParams.cancellationDateFrom)
      : null;
    let endDate = urlParams.cancellationDateTo
      ? moment(urlParams.cancellationDateTo)
      : null;

    if (!startDate && urlParams.cancellationDate) {
      return { startDate: moment(urlParams.cancellationDate) };
    }

    return { startDate, endDate };
  };
  const initPayoutDate = () => {
    let startDate = urlParams.payoutDateFrom
      ? moment(urlParams.payoutDateFrom)
      : null;
    let endDate = urlParams.payoutDateTo
      ? moment(urlParams.payoutDateTo)
      : null;

    if (!startDate && urlParams.payoutDate) {
      return { startDate: moment(urlParams.payoutDate) };
    }

    return { startDate, endDate };
  };

  // states to know if we're sending single or date range
  const [singleDateCancellation, setIsSingleDateCancellation] = useState(false);
  const [singleDatePayout, setIsSingleDayPayout] = useState(false);

  // url param states
  const [invoiceId, setInvoiceId] = useState(urlParams.invoiceId);
  const [name, setName] = useState(urlParams.invoiceName);
  const [order, setOrder] = useState(urlParams.orderReference);
  const [invoiceRef, setInvoiceRef] = useState(urlParams.invoiceReference);
  const [status, setStatus] = useState(urlParams.status ?? undefined);
  const [transferred, setTransferred] = useState(urlParams.transferred);
  const [cancellationDate, setCancellationDate] =
    useState(initCancellationDate);
  const [payoutDate, setPayoutDate] = useState(initPayoutDate);

  // state for toggling official document mode
  const [isOfficialDocumentMode, setIsOfficialDocumentMode] = useState(false);
  const [showFundsSelect, setShowFundsSelect] = useState(false);

  // modal state
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  // debounced url param values
  const debouncedInvoiceId = useDebounce(invoiceId, 750);
  const debouncedName = useDebounce(name, 750);
  const debouncedClubOrCourseName = useDebounce(clubNameState, 750);
  const debouncedOrder = useDebounce(order, 750);
  const debouncedInvoiceRef = useDebounce(invoiceRef, 750);
  const debouncedStatus = useDebounce(status, 750);
  const debouncedCancellationDate = useDebounce(cancellationDate, 750);
  const debouncedPayoutDate = useDebounce(payoutDate, 750);
  const debouncedTransferred = useDebounce(transferred, 750);

  // hook to update url params
  useUpdateUrlSearchParams(
    history,
    [
      { key: "invoiceId", value: debouncedInvoiceId },
      { key: "invoiceName", value: debouncedName },
      { key: "invoiceReference", value: debouncedInvoiceRef },
      { key: "orderReference", value: debouncedOrder },
      { key: "clubOrCourseName", value: debouncedClubOrCourseName },
      { key: "transferred", value: debouncedTransferred },
      { key: "status", value: debouncedStatus },
      ...URLHelper.formatDateQueryIndividualDates(
        "cancellationDate",
        debouncedCancellationDate,
        singleDateCancellation
      ),
      ...URLHelper.formatDateQueryIndividualDates(
        "payoutDate",
        debouncedPayoutDate,
        singleDatePayout
      )
    ],
    [
      debouncedInvoiceId,
      debouncedName,
      debouncedClubOrCourseName,
      debouncedOrder,
      debouncedStatus,
      debouncedCancellationDate,
      debouncedPayoutDate,
      debouncedInvoiceRef,
      debouncedTransferred
    ]
  );

  const onDatesChangeCancellation = (dates, isSingleDate = false) => {
    const { startDate, endDate } = dates;
    if (isSingleDate !== singleDateCancellation) {
      setIsSingleDateCancellation(isSingleDate);
    }
    setCancellationDate({ startDate, endDate });
  };

  const onDatesChangePayout = (dates, isSingleDate = false) => {
    const { startDate, endDate } = dates;
    if (isSingleDate !== singleDatePayout) {
      setIsSingleDayPayout(isSingleDate);
    }
    setPayoutDate({ startDate, endDate });
  };

  const toggleOfficialDocument = () => {
    if (isOfficialDocumentMode) {
      setIsOfficialDocumentMode(!isOfficialDocumentMode);
      setTransferred([]);
      setStatus([]);
      refetchInvoices(buildFilter);
      if (showFundsSelect) setShowFundsSelect(false);
      return;
    }
    setIsOfficialDocumentMode(!isOfficialDocumentMode);
    setTransferred([TRANSFERED_OPTIONS[1].value, TRANSFERED_OPTIONS[2].value]);
    setStatus([
      STATUS_OPTIONS[1].value,
      STATUS_OPTIONS[2].value,
      STATUS_OPTIONS[3].value
    ]);
    // clearing rest of filters
    if (invoiceId) setInvoiceId("");
    if (name) setName("");
    if (order) setOrder("");
    if (invoiceRef) setInvoiceRef("");
    if (clubNameState) setClubName("");
  };

  return (
    <div>
      <Row className="mb-3">
        <Col lg={3} md={6} sm={6}>
          <label htmlFor="input-id-info">Id</label>
          <Input
            id="input-id-info"
            type="text"
            placeholder="Id"
            onChange={(e) => setInvoiceId(e.target.value)}
            value={invoiceId}
            disabled={isOfficialDocumentMode}
          />
        </Col>
        <Col lg={3} md={6} sm={6}>
          <label htmlFor="input-name-info">Name</label>
          <Input
            id="input-name-info"
            type="text"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            disabled={isOfficialDocumentMode}
          />
        </Col>
        <Col lg={3} md={6} sm={6}>
          <label htmlFor="input-order-ref">Order</label>
          <Input
            id="input-order-ref"
            type="text"
            placeholder="Order ref, objectID"
            onChange={(e) => setOrder(e.target.value)}
            value={order}
            disabled={isOfficialDocumentMode}
          />
        </Col>
        <Col lg={3} md={6} sm={6}>
          <label htmlFor="input-invoice-ref">Invoice #</label>
          <Input
            id="input-invoice-ref"
            type="text"
            placeholder="Invoice ref, objectID"
            onChange={(e) => setInvoiceRef(e.target.value)}
            defaultValue={invoiceRef}
            disabled={isOfficialDocumentMode}
          />
        </Col>
        <Col lg={3} md={6} sm={6}>
          <label htmlFor="input-club-course-name">Club/course name</label>
          <Input
            id="input-club-course-name"
            type="text"
            placeholder="Club/course"
            value={clubNameState || ""}
            onChange={(e) => {
              setClubName(e.target.value);
            }}
            disabled={isOfficialDocumentMode}
          />
        </Col>
        <Col lg={4} md={6} sm={6} mb={2} xs={12}>
          <label>Invoice Cancellation date</label>
          <div className="DateRangePicker__custom-style">
            <ToggleableDateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={cancellationDate?.startDate}
              startDateId="invoiceCancellationFrom"
              endDate={cancellationDate?.endDate}
              endDateId="invoiceCancellationTo"
              onDatesChange={onDatesChangeCancellation}
              showClearDates
              isOutsideRange={() => false}
              singleDatePickerToggler
              popoverId="cancellation-date"
            />
          </div>
        </Col>
        <Col lg={4} md={6} sm={6} mb={2} xs={12}>
          <label>Invoice Payout date</label>
          <div className="DateRangePicker__custom-style">
            <ToggleableDateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={payoutDate?.startDate}
              startDateId="invoicePayoutFrom"
              endDate={payoutDate?.endDate}
              endDateId="invoicePayoutTo"
              onDatesChange={onDatesChangePayout}
              showClearDates
              isOutsideRange={() => false}
              canPickSingleDate
              popoverId="payout-date"
            />
          </div>
        </Col>
        <Col lg={4}>
          <label htmlFor="input-status">Status</label>
          <InvoiceStatusSelect
            value={status}
            isDisabled={isOfficialDocumentMode}
            onChange={(value) => {
              setStatus(value.length > 0 ? value : null);
            }}
            options={STATUS_OPTIONS.map((option) => ({
              label: option.label,
              value: option.value
            }))}
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="mark-as-transferred">Funds transferred</label>
          <InvoiceStatusSelect
            value={transferred}
            isDisabled={isOfficialDocumentMode}
            options={TRANSFERED_OPTIONS.map((option) => ({
              label: option.label,
              value: option.value
            }))}
            onChange={(value) =>
              setTransferred(value.length > 0 ? value : null)
            }
          />
        </Col>
      </Row>
      <div className="w-100 d-flex align-items-end between">
        <div className="mr-auto d-flex flex-column">
          {!isOfficialDocumentMode && (
            <Button
              color="link"
              onClick={() => setShowFundsSelect(!showFundsSelect)}
              className="mr-auto p-0"
            >
              {showFundsSelect ? "Hide " : "Show "}funds not transferred
            </Button>
          )}
          <div className="d-inline-block mr-2">Pages: {pageCount}</div>
          <div className="d-inline-block">Matches: {matchCount}</div>
        </div>
        {(showFundsSelect || isOfficialDocumentMode) && (
          <MarkAsTransferredSelect
            buildFilter={buildFilter}
            isOfficialDocumentMode={isOfficialDocumentMode}
            disabled={invoiceList.length < 1}
          />
        )}
        <div className="d-flex flex-column">
          <Button
            color="link"
            onClick={() => setShowCurrencyModal(!showCurrencyModal)}
            className="ml-4 p-0"
          >
            Show Currency Modal
          </Button>
          {isOfficialDocumentMode ? (
            <Button onClick={toggleOfficialDocument} color="danger">
              Cancel Report Preview
            </Button>
          ) : (
            <Button onClick={toggleOfficialDocument} color="primary">
              Preview Official Report
            </Button>
          )}
        </div>
      </div>
      <CurrencyModal
        isOpen={showCurrencyModal}
        toggle={() => setShowCurrencyModal(!showCurrencyModal)}
      />
    </div>
  );
};

export default InvoiceSearch;
