// Vendor
import React, { useContext } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import moment from "moment-timezone";
import { useMutation } from "@apollo/client";

// App
import CardRow from "../../ui/CardRow";
import { InternalCancellationButton } from "./InternalCancellationButton";
import { ProviderCancellationButton } from "./ProviderCancellationButton";
import { OrderSendEmailButton } from "./OrderSendEmailButton";
import { SEND_ORDER_CANCELLATION_EMAIL } from "../../../common/Mutations";
import { OrderContext } from "../Order";
import { BookingStatus } from "../collections/OfflineBookingStatus";
import { HandleOfflineOrderButton } from "./HandleOfflineOrderButton";

/**
 * @param { Object } props
 * @param { OrderTeeTime } props.teetime
 * @param { string } props.clubBookingId
 * @param { string } props.localTimeZone
 * @param { string } props.provider
 */
export function TeeTimeDetails({
  teetime,
  clubBookingId,
  localTimeZone,
  provider
}) {
  const [sendCancellationConfirmation, { data, loading, error }] = useMutation(
    SEND_ORDER_CANCELLATION_EMAIL
  );
  const {
    state: {
      formatted: { _id: orderId },
      raw: { offlineBookings }
    }
  } = useContext(OrderContext);

  const isAfterCancelDate = moment(teetime.cancelDateTime).isBefore();
  const isAfterPlayDate = moment(teetime.playDate).isBefore();
  const hasErrors = teetime.bookingErrors?.length > 0;
  const canCancelProvider =
    !teetime.cancelled && !isAfterCancelDate && !hasErrors;
  const canCancelInternal =
    !teetime.cancelled && !isAfterPlayDate && !hasErrors;

  const offlineBookingStatus =
    offlineBookings.find((o) => o._id === teetime.teeSheetOrderReference)
      ?.bookingStatus ?? "NA";

  function handleSendCancellationEmail(email) {
    sendCancellationConfirmation({
      variables: {
        orderId,
        teetimeId: teetime._id,
        targetEmail: email
      }
    });
  }

  return (
    <Card className="border-0">
      <hr className="mb-0 mt-0 border-dark" />
      <CardBody className="text-center">
        <CardTitle className="mb-2">
          <p className="font-weight-bold mb-0">Play date</p>
          {moment
            .tz(teetime.playDate, localTimeZone)
            .format("YYYY-MM-DD HH:mm")}
        </CardTitle>
        {hasErrors && (
          <p className="text-danger text-uppercase font-weight-bold mb-2">
            Failed order item
          </p>
        )}
        {teetime.cancelled && (
          <p className="text-uppercase font-weight-bold text-danger mb-0">
            Cancelled
          </p>
        )}
      </CardBody>

      <hr className="mb-3 mt-0" />

      <div className="d-flex justify-content-center">
        <CardRow
          label="Provider order reference"
          value={teetime.teeSheetOrderReference ?? "N/A"}
        />
      </div>
      <div className="d-flex flex-column justify-content-center">
        {provider === "GF365_SIMPLE" ? (
          <>
            <CardRow
              label="Booking status"
              value={BookingStatus[offlineBookingStatus].label}
              valueClass={BookingStatus[offlineBookingStatus].style}
            />
            <HandleOfflineOrderButton
              orderStatus={offlineBookingStatus}
              bookingId={teetime.teeSheetOrderReference}
            />
          </>
        ) : (
          <CardRow
            label="Order item ID's"
            value={
              teetime.bookingDetailsIds.length
                ? teetime.bookingDetailsIds.join(", ")
                : "N/A"
            }
          />
        )}
      </div>

      <hr className="mb-3 mt-0" />

      <dl>
        <CardRow
          className="my-2"
          label="Tee"
          value={teetime.startingTee}
        />

        <div className="d-flex justify-content-center">
          <CardRow label="Players" value={teetime.players} />
          <CardRow
            value={teetime.buggyIncluded ? "Buggy included" : teetime.buggies}
            valueClass={
              teetime.buggyIncluded
                ? "text-success d-inline-block mb-0"
                : undefined
            }
            label="Buggies"
          />
        </div>

        <div className="d-flex justify-content-center">
          <CardRow
            className="mb-3"
            label="Price"
            valueClass="key-figure key-figure-lg mb-0"
            value={teetime.price}
          />
          {teetime.paidInAdvance > 0 && (
            <CardRow
              className="mb-3"
              label="Prepaid"
              valueClass="key-figure key-figure-lg mb-0"
              value={teetime.paidInAdvance}
            />
          )}
          {teetime.fee > 0 && (
            <CardRow
              className="mb-3"
              label="Fee"
              valueClass="key-figure key-figure-lg mb-0"
              value={teetime.fee}
            />
          )}
          {teetime.discountAmount < 0 && (
            <CardRow
              className="mb-3"
              label="Discount"
              valueClass="key-figure key-figure-lg mb-0"
              value={Math.abs(teetime.discountAmount)}
            />
          )}
        </div>

        {teetime.cancelled && (
          <div>
            <CardRow
              label="Cancelled at"
              value={moment(teetime.cancelledDate).format("YYYY-MM-DD HH:mm")}
            />
            <OrderSendEmailButton
              id={`btn-cancellation-email-${teetime._id}`}
              onClick={handleSendCancellationEmail}
              loading={loading}
              error={error}
              success={data?.sendCancellationConfirmation.success}
            >
              Send cancellation confirmation
            </OrderSendEmailButton>
          </div>
        )}
        {!teetime.cancelled && (
          <CardRow
            label="Cancel by"
            value={moment(teetime.cancelDateTime).format("YYYY-MM-DD HH:mm")}
            valueClass={`key-figure key-figure-sm mb-0 ${
              isAfterCancelDate ? "text-muted" : ""
            }`}
          />
        )}
      </dl>

      {!teetime.cancelled && (
        <p
          className={`font-weight-bold mt-0 mb-3 upper ${
            teetime.payOnSite ? "text-danger" : "text-success"
          }`}
        >
          {teetime.payOnSite ? "Pay on site" : "Paid"}
        </p>
      )}

      {canCancelProvider && (
        <>
          <ProviderCancellationButton
            teetimeId={teetime._id}
            clubBookingId={clubBookingId}
          />
        </>
      )}

      {canCancelInternal && (
        <>
          <InternalCancellationButton teetimeId={teetime._id} />
        </>
      )}
    </Card>
  );
}
