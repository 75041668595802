// Vendor
import React, {
  useEffect,
  useState,
  useMemo,
  useReducer,
  useContext,
  useCallback
} from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, FormGroup, Row, Table } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Image } from "cloudinary-react";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
// App
import Loader from "../ui/Loader";
import DynamicTransTable from "../ui/DynamicTransTable/DynamicTransTable";
import { SITE, LINKABLE_COURSES } from "../../common/Queries";
import { CREATE_SITE, UPDATE_SITE } from "../../common/Mutations";
import { GOLF_AREAS } from "../../common/Queries";
import SelectTable from "../ui/SelectTable";
import PartnerLinkSelect from "./PartnerLinkSelect";
import siteReducer from "./SiteReducer";
import { LayoutContext } from "../app/Layout";
import HighlightList from "../ui/Highlights/HighlightList";
import { MapFields } from "./mapFields/MapFields";
import { InputField } from "../ui/FunctionalComponents";
import ImageUploader from "./ImageUploader";
import LocaleHelper from "../../common/LocaleHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteCommissionModal from "./SiteCommissionModal";
import { EMAIL_VALIDATOR } from "../../common/Validators";

export const SiteContext = React.createContext();
const initinalI18n = LocaleHelper.generateI18nObject([
  { name: "ids", defaultValue: [] }
]);

function Site(props) {
  function handleSave() {
    // convert into SiteInput format
    const input = {
      name: state.name,
      companyName: state.companyName,
      siteFonts: state.siteFonts,
      logoTopSlug: state.logoTopSlug,
      logoBottomSlug: state.logoBottomSlug,
      socialMedia: state.socialMedia,
      parentSite: state.parentSite,
      commission: parseFloat(state.commission),
      emails: state.emails,
      address: state.address,
      postalCode: state.postalCode,
      city: state.city,
      state: state.state,
      country: state.country,
      startPageInput: {
        headerImageSlug: state.startPage.headerImageSlug,
        logoStartPageSlug: state.startPage.logoStartPageSlug,
        headerImageGradient: state.startPage.headerImageGradient,
        golfCourses: state.startPage.courses
          ? state.startPage.courses.map((course) => course._id)
          : [],
        highlights: state.startPage.highlights,
        golfDestinations: state.startPage.golfDestinations
          ? state.startPage.golfDestinations.map((dest) => dest._id)
          : [],
        partnerLinks: {
          ids: state.startPage.partnerLinks?.viewModelIds
            ? state.startPage.partnerLinks.viewModelIds.map((dest) => dest._id)
            : [],
          i18n: state.startPage.partnerLinks?.viewModelI18n
            ? Object.keys(initinalI18n).reduce((current, item) => {
                current[item] = {};
                current[item].ids = state.startPage.partnerLinks.viewModelI18n[
                  item
                ].ids.map((dest) => dest._id);
                return current;
              }, {})
            : i18n
        },
        testimonials: state.startPage.testimonials,
        startPageMap: state.startPage.startPageMap,
        golfDestinationPills: state.startPage.golfDestinationPills
          ? state.startPage.golfDestinationPills.map((pill) => ({
              ...pill,
              paramValue: encodeURI(pill.text)
            }))
          : []
      }
    };

    state._id
      ? updateSite({
          variables: {
            input,
            siteId: state._id
          }
        })
      : createSite({ variables: { input } });
  }

  function redirectWarning() {
    if (hasChanged) return "This will discard all unsaved edits. Are you sure?";
  }

  const [partnerLinkIds, setPartnerLinkIds] = useState([]);
  const [i18n, setI18n] = useState(initinalI18n);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingEmailIndex, setEditingEmailIndex] = useState(null); // Track index of the editing email
  const [currentInputValue, setCurrentInputValue] = useState(""); // Current input value

  // Context
  const { addAlert } = useContext(LayoutContext);

  // the state to pass down to children, and dispatch method to change it
  const [state, dispatch] = useReducer(siteReducer, { startPage: {} });

  //state
  const [hasChanged, setHasChanged] = useState(false);

  const middleware = useCallback(
    (event) => {
      if (!hasChanged) setHasChanged(true);
      dispatch(event);
    },
    [dispatch, hasChanged]
  );

  // memoize so we only trigger reloads of children when the state changes
  const contextValue = useMemo(
    () => ({ state, dispatch: middleware }),
    [state, middleware]
  );

  // Query
  const [getSite, { loading: queryLoading, error: queryError }] = useLazyQuery(
    SITE,
    {
      onCompleted: (data) => {
        if (data.site.startPage.partnerLinks) {
          setPartnerLinkIds(data.site.startPage.partnerLinks.viewModelIds);
          setI18n(data.site.startPage.partnerLinks.viewModelI18n);
        }
        dispatch({ type: "INIT", payload: data.site });
      }
    }
  );
  useEffect(() => {
    if (props.match.params.id && state._id !== props.match.params.id) {
      getSite({ variables: { id: props.match.params.id } });
    }
  }, [getSite, props.match.params.id, state._id]);
  // Mutation
  const [updateSite, { loading: mutationLoading }] = useMutation(UPDATE_SITE, {
    onCompleted: (res) => {
      if (!res.updateSite.ok) {
        return addAlert({ color: "danger", message: "Could not save Site." });
      }
      dispatch({ type: "INIT", payload: res.updateSite.site });
      addAlert({
        color: "success",
        message: "Site updated successfully saved."
      });
    },
    onError: () =>
      addAlert({ color: "danger", message: "Could not save Site." })
  });

  const [createSite, { loading: createLoading }] = useMutation(CREATE_SITE, {
    onCompleted: (res) => {
      if (!res.createSite.ok) {
        return addAlert({ color: "danger", message: "Could not create Site." });
      }
      dispatch({ type: "INIT", payload: res.createSite.site });
      addAlert({
        color: "success",
        message: "Site created successfully."
      });
    },
    onError: () =>
      addAlert({ color: "danger", message: "Could not create Site." })
  });

  useEffect(() => {
    window.onbeforeunload = redirectWarning;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLogoTopImageUpload = useCallback(
    (slug) => {
      middleware({ type: "LOGO_TOP_SLUG", payload: slug });
    },
    [middleware]
  );
  const onLogoBottomImageUpload = useCallback(
    (slug) => {
      middleware({ type: "LOGO_BOTTOM_SLUG", payload: slug });
    },
    [middleware]
  );
  const onStartPageLogoImageUpload = useCallback(
    (slug) => {
      middleware({ type: "START_PAGE_LOGO_SLUG", payload: slug });
    },
    [middleware]
  );
  const onHeaderImageUpload = useCallback(
    (slug) => {
      middleware({ type: "HEADER_IMAGE_SLUG", payload: slug });
    },
    [middleware]
  );

  const addEmails = (newEmail) => {
    if (state.emails && state.emails.includes(newEmail)) {
      addAlert({
        color: "danger",
        message: "Invoice email already exists."
      });
    } else {
      middleware({ type: "ADD_EMAIL", payload: newEmail });
    }
  };

  const removeEmail = (email) => {
    middleware({ type: "REMOVE_EMAIL", payload: email });
  };
  const handleToggleSiteModal = () => setIsModalOpen(!isModalOpen);

  const editEmail = (email, index) => {
    setEditingEmailIndex(index);
    setCurrentInputValue(email);
  };
  const saveEmail = (email, newEmail) => {
    if (state.emails && !state.emails.includes(newEmail)) {
      middleware({
        type: "UPDATE_EMAIL",
        payload: { oldEmail: email, newEmail: newEmail }
      });
      setEditingEmailIndex(null);
      setCurrentInputValue("");
    } else {
      addAlert({
        color: "danger",
        message: "That email already exists."
      });
    }
  };

  // Guards
  if (props.match.params.id && state.loaded === false) return null;
  if (queryLoading || (!state.loaded && props.match.params.id))
    return <Loader fullscreen={true} />;
  if (queryError)
    return <p className="text-danger">Error loading start page</p>;

  return (
    <SiteContext.Provider value={contextValue}>
      <div className="page">
        <h1>Site</h1>
        <AvForm
          onValidSubmit={handleSave}
          onInvalidSubmit={() =>
            addAlert({ color: "danger", message: "Invalid inputs." })
          }
        >
          <Row>
            <Col md={8} lg={6}>
              <label>Id</label>
              <p>{state._id}</p>
            </Col>
          </Row>
          <h3>General information</h3>
          <Row className="mt-1">
            <Col md={4} lg={3}>
              <label>Name</label>
              <InputField
                targetKey="name"
                value={state.name}
                onChange={(changeEvent) =>
                  middleware({ type: "NAME", payload: changeEvent.value })
                }
                required
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Company name</label>
              <InputField
                targetKey="companyName"
                value={state.companyName}
                onChange={(changeEvent) =>
                  middleware({
                    type: "COMPANY_NAME",
                    payload: changeEvent.value
                  })
                }
                placeholder="Subscription Assets AB"
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Parent Site URL</label>
              <InputField
                targetKey="parentsiteurl"
                value={state.parentSite ? state.parentSite.url : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "PARENT_SITE_URL",
                    payload: changeEvent.value
                  })
                }
                placeholder="https://www.example.com"
                required={false}
                options={{ type: "url" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Parent Site name</label>
              <InputField
                targetKey="parentsitename"
                value={state.parentSite ? state.parentSite.name : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "PARENT_SITE_NAME",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Commission in %</label>
              <InputField
                targetKey="commission"
                value={state.commission}
                type="number"
                placeholder="0"
                onChange={(changeEvent) =>
                  middleware({ type: "COMMISSION", payload: changeEvent.value })
                }
                requiredInputField
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Address</label>
              <InputField
                targetKey="address"
                placeholder="Street 1"
                value={state.address}
                onChange={(changeEvent) =>
                  middleware({
                    type: "ADDRESS",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Postal Code</label>
              <InputField
                targetKey="postalCode"
                placeholder="123 45"
                value={state.postalCode}
                onChange={(changeEvent) =>
                  middleware({
                    type: "POSTAL_CODE",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>City</label>
              <InputField
                targetKey="city"
                placeholder="Stockholm"
                value={state.city}
                onChange={(changeEvent) =>
                  middleware({
                    type: "CITY",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>State</label>
              <InputField
                targetKey="state"
                placeholder="Stockholm state"
                value={state.state}
                onChange={(changeEvent) =>
                  middleware({
                    type: "STATE",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={4} lg={3}>
              <label>Country</label>
              <InputField
                targetKey="country"
                placeholder="Sweden"
                value={state.country}
                onChange={(changeEvent) =>
                  middleware({
                    type: "COUNTRY",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
          </Row>
          <h3>Site commission report settings</h3>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <label>Invoice Email</label>
              <InputField
                targetKey="email"
                value={state.email}
                placeholder="email@email.com"
                required={false}
                className="w-100"
                validator={EMAIL_VALIDATOR}
                onChange={(changeEvent) =>
                  middleware({ type: "EMAIL", payload: changeEvent.value })
                }
                options={{ type: "email" }}
              />
            </Col>
            <Col
              sm={2}
              md={2}
              lg={3}
              className="d-flex align-items-center mt-1"
            >
              <Button
                type="button"
                className="w-auto btn-lg text-capitalize"
                onClick={() => addEmails(state.email)}
              >
                Add
              </Button>
            </Col>
            <Col
              sm={4}
              md={6}
              lg={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                color="primary"
                type="button"
                className="w-auto btn-lg px-sm-3 py-sm-3 text-capitalize"
                onClick={handleToggleSiteModal}
              >
                <small>Manual PDFs</small>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faFilePdf}
                  title="Report"
                  className="ml-2"
                />
              </Button>
            </Col>
          </Row>
          <Row>
            {state.emails && state.emails.length >= 1 && (
              <Table striped className="mx-3">
                <tbody>
                  {state.emails &&
                    state.emails.map((email, index) => {
                      return (
                        <tr className="text-center" key={index}>
                          <td className="align-middle">
                            {editingEmailIndex === index ? (
                              <InputField
                                value={currentInputValue}
                                placeholder="email@email.com"
                                required={false}
                                onChange={(changeEvent) =>
                                  setCurrentInputValue(changeEvent.value)
                                }
                                options={{ type: "email" }}
                              />
                            ) : (
                              <p className="m-0">{email}</p>
                            )}
                          </td>
                          <td className="text-right h-auto">
                            {editingEmailIndex === index ? (
                              <>
                                <Button
                                  className="d-inline-flex py-0 px-2 mr-2"
                                  color="primary"
                                  onClick={() =>
                                    saveEmail(email, currentInputValue)
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  className="d-inline-flex py-0 px-2 btn-outline-light"
                                  onClick={() => {
                                    setEditingEmailIndex(null);
                                    setCurrentInputValue("");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              // Edit and Delete buttons
                              <>
                                <Button
                                  onClick={() => editEmail(email, index)}
                                  color="link"
                                  type="button"
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon="pencil-alt" />
                                </Button>
                                <FontAwesomeIcon
                                  className="text-muted cursor-pointer"
                                  icon="trash-alt"
                                  onClick={() => removeEmail(email)}
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </Row>
          <h3>Google Fonts</h3>
          <Row>
            <Col md={8} lg={6}>
              <label>Header</label>
              <InputField
                targetKey="headerfont"
                value={state.siteFonts ? state.siteFonts.header : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "HEADER_FONT",
                    payload: changeEvent.value
                  })
                }
                options={{ type: "string" }}
              />
            </Col>
            <Col md={8} lg={6}>
              <label>Body</label>
              <InputField
                targetKey="bodyfont"
                value={state.siteFonts ? state.siteFonts.body : ""}
                onChange={(changeEvent) =>
                  middleware({ type: "BODY_FONT", payload: changeEvent.value })
                }
                options={{ type: "string" }}
              />
            </Col>
          </Row>
          <h3>Social media</h3>
          <Row>
            <Col md={8} lg={6}>
              <label>Facebook</label>
              <InputField
                targetKey="facebook"
                value={state.socialMedia ? state.socialMedia.facebookUrl : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "SOCIAL_MEDIA_FACEBOOK",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={8} lg={6}>
              <label>LinkedIn</label>
              <InputField
                targetKey="linkedin"
                value={state.socialMedia ? state.socialMedia.linkedinUrl : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "SOCIAL_MEDIA_LINKEDIN",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
            <Col md={8} lg={6}>
              <label>Instagram</label>
              <InputField
                targetKey="instagram"
                value={state.socialMedia ? state.socialMedia.instagramUrl : ""}
                onChange={(changeEvent) =>
                  middleware({
                    type: "SOCIAL_MEDIA_INSTAGRAM",
                    payload: changeEvent.value
                  })
                }
                required={false}
                options={{ type: "string" }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8} lg={6}>
              <label>Top Logo Image</label>
              <ImageUploader
                siteId={state._id}
                onImageUpload={onLogoTopImageUpload}
                prefix="logoTop"
              />

              {state.logoTopSlug && (
                <div className="d-flex w-100">
                  <Image
                    className="rounded mt-3"
                    width={550}
                    height="230"
                    publicId={state.logoTopSlug}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={8} lg={6}>
              <label>Bottom Logo Image</label>
              <ImageUploader
                siteId={state._id}
                onImageUpload={onLogoBottomImageUpload}
                prefix="logoBottom"
              />

              {state.logoBottomSlug && (
                <div className="d-flex w-100">
                  <Image
                    className="rounded mt-3"
                    width={550}
                    height="230"
                    publicId={state.logoBottomSlug}
                  />
                </div>
              )}
            </Col>
          </Row>
          <div className="mt-5">
            <h2>Startpage</h2>
            <div className="my-5">
              <Row>
                <Col md={8} lg={6}>
                  <label>Startpage Logo Image</label>
                  <ImageUploader
                    siteId={state._id}
                    onImageUpload={onStartPageLogoImageUpload}
                    prefix="startPageLogo"
                  />

                  {state.startPage.logoStartPageSlug && (
                    <div className="d-flex w-100">
                      <Image
                        className="rounded mt-3"
                        width={550}
                        height="230"
                        publicId={state.startPage.logoStartPageSlug}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={8} lg={6}>
                  <label>Header Image</label>

                  <ImageUploader
                    siteId={state._id}
                    onImageUpload={onHeaderImageUpload}
                    prefix="header"
                  />
                  {state.startPage.headerImageSlug && (
                    <div className="d-flex w-100 ">
                      <div
                        style={
                          state.startPage.headerImageGradient &&
                          state.startPage.headerImageGradient.topColor &&
                          state.startPage.headerImageGradient.bottomColor
                            ? {
                                backgroundImage: `linear-gradient(to bottom, ${state.startPage.headerImageGradient.topColor} 0%, ${state.startPage.headerImageGradient.bottomColor} 100%)`,
                                position: "absolute",
                                width: "100%",
                                height: "75%",
                                opacity: "0.9"
                              }
                            : undefined
                        }
                      ></div>
                      <img
                        alt="headerImage"
                        className="w-100 mt-3"
                        src={`https://res.cloudinary.com/greenfee365/image/upload/w_1024/${state.startPage.headerImageSlug}`}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={8} lg={6}>
                  <label>Top color</label>
                  <InputField
                    required={false}
                    targetKey="gradienttop"
                    type="color"
                    value={
                      state.startPage.headerImageGradient &&
                      state.startPage.headerImageGradient.topColor
                    }
                    onChange={(changeEvent) => {
                      middleware({
                        type: "HEADER_IMAGE_GRADIENT_TOP",
                        payload: changeEvent.value
                      });
                    }}
                  />
                  <label>Bottom color</label>
                  <InputField
                    required={false}
                    targetKey="gradientbottom"
                    type="color"
                    value={
                      state.startPage.headerImageGradient &&
                      state.startPage.headerImageGradient.bottomColor
                    }
                    onChange={(changeEvent) =>
                      middleware({
                        type: "HEADER_IMAGE_GRADIENT_BOTTOM",
                        payload: changeEvent.value
                      })
                    }
                  />
                </Col>
              </Row>
            </div>

            <SelectTable
              onChange={(value) =>
                middleware({ type: "GOLF_COURSES", payload: value })
              }
              selectQuery={LINKABLE_COURSES}
              listItems={state.startPage.courses}
              selectContainerClass="col-6"
              listContainerClass="col-12"
              title={"Golf Courses"}
            />

            <MapFields />

            <HighlightList
              highlights={state.startPage.highlights}
              onChange={(newHighlightList) =>
                middleware({ type: "HIGHLIGHTS", payload: newHighlightList })
              }
            />

            <SelectTable
              onChange={(value) =>
                middleware({ type: "GOLF_DESTINATIONS", payload: value })
              }
              selectQuery={GOLF_AREAS}
              selectQueryOptions={{ variables: { publishStatus: "PUBLISHED" } }}
              listItems={state.startPage.golfDestinations}
              selectContainerClass="col-6"
              listContainerClass="col-12"
              title={"Golf Destinations"}
            />

            <PartnerLinkSelect
              onChange={(event) => {
                const type =
                  event.key === "partnerLinkIds" ? "PARTNER_LINK_IDS" : "I18N";
                middleware({ type, payload: event.value });
              }}
              partnerLinkIds={
                state.startPage.partnerLinks?.viewModelIds || partnerLinkIds
              }
              partnerLinkIdsOld={partnerLinkIds}
              i18n={state.startPage.partnerLinks?.viewModelI18n || i18n}
              i18nOld={i18n}
            />
            <div className="my-5">
              <h3>Golf Destination Pills</h3>
              <DynamicTransTable
                onChange={(value) =>
                  middleware({ type: "GOLF_DESTINATION_PILLS", payload: value })
                }
                translationField={{
                  key: "text",
                  header: "Area"
                }}
                additionalFields={[{ key: "param", header: "Param" }]}
                items={state.startPage.golfDestinationPills}
                id="golf-dest-pills"
              />
            </div>

            <div className="my-5">
              <h3>Testimonials</h3>
              <DynamicTransTable
                onChange={(value) =>
                  middleware({ type: "TESTIMONIALS", payload: value })
                }
                translationField={{
                  key: "testimonial",
                  header: "Testimonial"
                }}
                additionalFields={[{ key: "name", header: "Name" }]}
                items={state.startPage.testimonials}
                id="testimonials"
              />
            </div>
          </div>
          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              <FormGroup>
                <Button
                  size="lg"
                  color={hasChanged ? "secondary" : "gray"}
                  disabled={!hasChanged || mutationLoading || createLoading}
                  type="submit"
                >
                  Save
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
      </div>
      {isModalOpen && (
        <SiteCommissionModal
          isOpen={isModalOpen}
          toggle={handleToggleSiteModal}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </SiteContext.Provider>
  );
}

export default withRouter(Site);
