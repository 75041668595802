export const HIGHLIGHT_FIELDS = `
  _id
  type
  url
  openInNew
  imageId
  altTag
  title
  text
`;
export const CARD_REFERENCE = `
cardReference
createdAt
_id
`;

export const START_PAGE_FIELDS = `
  headerImageSlug
  logoStartPageSlug
  headerImageGradient {
    topColor
    bottomColor
  }
  courses{
    _id
    name
  }
  highlights {
    ${HIGHLIGHT_FIELDS}
  }
  golfDestinations{
    _id
    name
  }
  partnerLinks{
    ids
    i18n
    viewModelIds
    viewModelI18n
  }
  golfDestinationPills{
    text
    param
    paramValue
    i18n
  }

  startPageMap{
    latitude
    longitude
    zoom
  }

  testimonials{
    name
    testimonial
    i18n
  }
`;

export const GOLF_AREA_FIELDS = `
  _id
  name
  slug
  coordinates {
    latitude
    longitude
  }
  radius
  zoom
  country
  countryCode
  currency
  imgId
  keyFigures {
    courses
    sunnyDays
    taxiPrice
    beerPrice
  }
  published
  description
  summary
  i18n
  categories {
    imgId
    title
    description
    i18n
  }
  fullCoursesObjects {
    _id
    name
    description
    i18n
  }
  cities {
    name
    imgId
  }
  highlights {
    ${HIGHLIGHT_FIELDS}
  }
`;

export const COMMENT_ATTRIBUTES = `
  _id
  userFullName
  body
  timestamp
  task {
    _id
    taskOpen
    closeDate
    closedBy
  }
`;

export const TIMETABLE_DAY_SPLIT_ATTRIBUTES = `
  formattedStartTime
  formattedEndTime
  interval
`;

export const TIMETABLE_DAY_OVERRIDE_ATTRIBUTES = `
  closed
  dayAvailability {
    ${TIMETABLE_DAY_SPLIT_ATTRIBUTES}
  }
`;

export const TIMETABLE_ATTRIBUTES = `
  _id
  formattedStartDate
  formattedEndDate
  formattedStartTime
  formattedEndTime
  interval
  courseId
  daySplitOverride {
    ${TIMETABLE_DAY_SPLIT_ATTRIBUTES}
  }
  weekdayOverride {
    days
    ${TIMETABLE_DAY_OVERRIDE_ATTRIBUTES}
  }
  dateOverride {
    formattedDates
    ${TIMETABLE_DAY_OVERRIDE_ATTRIBUTES}
  }
`;

// partials

export const SEASON_RATE_ATTRIBUTES = `
  _id
  seasonType{
    _id
    name
    color
    type
  }
  startDate
  endDate
  rates{
    scheduleType
    dayType{
      _id
      name
      type
    }
    startDate
    endDate
    prices{
      commission
      commissionType
      price
      rackPrice
      rateType
      multiplier
      targetRateId
      useTeeSheetProviderPricing
      addCommissionMarkup
      isOverridableByCourse
    }
    buggyIncluded
  }
`;

export const CONTACT_DETAILS = `
  address
  postalCode
  city
  state
  country
  countryCode
  phone
`;

export const COURSE_ATTRIBUTES = `
  _id
  slug
  teeSheetCourseId
  teeSheetClubId
  name
  holes
  par
  tees{
    name
    targetId
  }
  cancellationHours
  payoutHours
  daysOffset
  amenities
  dailyOrderEmail
  invoiceEmail
  invoiceEmails
  membership
  phone
  postalCode
  courseType
  architect
  currency
  active  
  payOnSite
  payOnBooking
  vatRate
  vatIdentificationNumber
  hiddenForSites
  description
  readMore
  index
  i18n
  areas
  placeId
  localTimeZone
  loc {
    type
    coordinates
  }
  seasonTypes{
    _id
    name
    type
    color
  }
  dayTypes{
    _id
    name
    type
  }
  seasonRates {
    ${SEASON_RATE_ATTRIBUTES}
  }
  keyValueStore {
    key
    value
  }
  ${CONTACT_DETAILS}
  commissionType
  commission
  amadeusActive
  amadeusCard {
    externalReference
  }
  isFeaturedOnAlgolia
  previousCardReferences {
    ${CARD_REFERENCE}
  }
`;

export const CLUB_ATTRIBUTES = `
  _id
  name
  slug
  dailyOrderEmail
  invoiceEmail
  invoiceEmails
  clubPanelUsers{
    _id
    email
  }
  website
  teeSheetClubId
  teeSheetProvider
  claimed
  commission
  commissionType
  #claimedDate
  accountManager{
    _id
    firstName
    lastName
  }
  contacts {
    name
    title
    email
  }
  courses {
    ${COURSE_ATTRIBUTES}
  }
  comments {
    ${COMMENT_ATTRIBUTES}
  }
  keyValueStore {
    key
    value
  }
  ${CONTACT_DETAILS}
  #updatedAt
  #deleted
  #deletedAt
`;

// ORDERS
export const ORDER_BASE_ATTRIBUTES = `
  _id
  firstName
  lastName
  email
  total
  payNowTotal
  orderReference
  updatedAt
  createdAt
  siteId
  voucher {
    _id
    code
    value
  }
  partnerNetwork {
    name
  }
  tourOperator {
    name
  }
  createdBy {
    user {
      _id
      email
    }
    tourOperator {
      _id
      name
    }
  }
  userId
  affiliateId {
    _id
    name
    username
  }
`;

export const ORDER_TEETIMES = `
  teetimes {
    _id
    courseId
    courseName
    slug
    startingTee
    buggies
    buggyIncluded
    buggyDisabled
    players
    playDate
    cancelled
    cancelledDate
    paidInAdvance
    cancelDateTime
    discountAmount
    currency
    price
    payOnSite
    fee
    bookingDetailsIds
    teeSheetOrderReference
    teeSheetCourseId
    bookingErrors {
      reason
    }
    ratesUsed {
      price
      rackPrice
      rateType
      targetRateId
      players
      dayType{
        _id
        name
        type
      }
      useTeeSheetPricing
    }
  }
`;

export const ORDER_CLUBS = `
  clubs {
    _id
    total
    payNowTotal
    vatRate
    clubId{
      _id
    }
    name
    teeSheetProvider
    teeSheetClubId
    localTimeZone
    ${ORDER_TEETIMES}
  }
`;

export const VOUCHER_ATTRIBUTES = `
  _id 
  code 
  value 
  startDate 
  endDate 
  comment 
`;

export const AFFILIATE_ATTRIBUTES = `
  _id
  aid
  cid
  isActive
  excludedTeesheetProviders
  name
  username
`;

export const PARTNER_NETWORK_ATTRIBUTES = `
  _id 
  name 
  value 
  users {
    email
    registered
    verified
  }
`;

export const TOUR_OPERATOR_ATTRIBUTES = `
  _id 
  name 
  value 
  users {
    email
    registered
    verified
  }
`;

export const PARTNER_LINK_ATTRIBUTES = `
  _id 
  name
  displayName
  linkUrl
  imageUrl
`;

export const EMAIL_RECIPIENTS_ATTRIBUTES = `
  _id
  email
  name {
    firstName
    lastName
  }
`;
export const EMAIL_TEMPLATES_ATTRIBUTES = `
  _id
  name
  subject
  emailBody
  recipientList
`;

export const INVOICE_BASE_ATTRIBUTES = `
  _id
  name
  customerNum
  invoiceNum
  disbursementNum
  dateIssued
  stripeOrderToken
  orderReference
  reference
  referenceTo{
    _id
    reference
  }
  subinvoiceNum
  description
  subinvoices {
    _id
    reference
  }
  clubId
  clubName
  courseName
  courseId
  address
  city
  state
  country
  userId
  userFirstname
  userLastname
  vatRate
  players
  seasonRateType
  total
  basis
  totalCommission
  VATIdNumber
  commission
  commissionType
  stripeFees
  amountToCharge
  currency
  cancellationDate
  playDate
  sellDate
  payoutDate
  status
  paymentType
  amadeusPaid
  createdAt
  fundsTransferred
  invoiceRows {
    courseId
    courseName
    courseType
    seasonRateType
    startingTee
    playDate
    players
    buggies
    commission
    commissionType
    address
    city
    country
    buggyIncluded
    cancelDateTime
    cancelled
    cancelledDate
    price
    paidInAdvance
    fee
    refundableAmount
    currency
    slug
    payOnSite
    teeTimeClubBookingStatus
    bookingDetailsIds
    teeSheetOrderReference
    teeSheetCourseId
    discountAmount
    ratesUsed {
      price
      rateType
      players
    }
  }
`;
export const VIRTUAL_CARD_ATTRIBUTES = `
  expiryDate
  externalReference
  cardReference
  cardNumber
  cardHolder
  availableBalance {
    amount
    currency
  }
  requestedBalance {
    amount
    currency
  }
  oncardBalance {
    amount
    currency
  }
  cvv
  startDate
  creationDate
  status
`;

export const SITE_FIELDS = `
  _id
  name
  companyName
  socialMedia {
    facebookUrl
    linkedinUrl
    instagramUrl
  }
  siteFonts {
    header
    body
  }
  parentSite {
    name
    url
  }
  logoTopSlug
  logoBottomSlug
  startPage { ${START_PAGE_FIELDS} }
  commission
  emails
  address
  postalCode
  city
  state
  country
`;

export const CURRENCY_FIELDS = `
  _id
  name
  value
  `;
