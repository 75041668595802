// vendor
import React, { useState, useEffect, useContext, useReducer } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";

// app
import { CLUB_GET_BY_SLUG } from "../../common/Queries";
import { CLUB_UPDATE, CLUB_CREATE } from "../../common/Mutations";
import { LayoutContext } from "../app/Layout";
import Loader from "../ui/Loader";
import NoMatch from "../ui/NoMatch";
import ClubGeneralInfo from "./categories/ClubGeneralInfo";
import ClubCourse from "./courses/ClubCourse";
import ClubContactPersonList from "./categories/ClubContactPersonList";
import clubReducer from "./clubReducer";
import ContactInfo from "../ui/ContactInfo";
import ClubProviderDetails from "./categories/ClubProviderDetails";
import ClubComments from "./clubComments/ClubComments";
import URLHelper from "../../common/URLHelper";
import LocaleHelper from "../../common/LocaleHelper";

// context
export const ClubContext = React.createContext(null);

function Club(props) {
  function init(data) {
    let club;
    if (!data)
      club = {
        contacts: [],
        courses: [],
        comments: []
      };
    else club = data.getClubBySlug;

    dispatch({ type: "INIT", payload: JSON.parse(JSON.stringify(club)) });
    if (urlParams.course) {
      setActiveTab(
        club.courses.findIndex((c) => c.slug === urlParams.course) + 2
      );
    }
  }

  function addCourse() {
    const newCourse = {
      name: "Untitled course",
      slug: "",
      holes: 18,
      par: 72,
      cancellationHours: 48,
      daysOffset: 0,
      amenities: [],
      dailyOrderEmail: "",
      invoiceEmail: "",
      address: "",
      city: "",
      state: "",
      country: "",
      countryCode: "",
      phone: "",
      membership: "Public",
      courseType: "Parkway",
      commission: state.commission ?? null,
      commissionType: state.commissionType ?? null,
      architect: "",
      active: false,
      description: "",
      i18n: LocaleHelper.generateI18nObject(["description"]),
      areas: [],
      placeId: null,
      localTimeZone: "",
      loc: {
        coordinates: [0, 0]
      },
      seasonRates: [],
      teeSheetClubId: state.teeSheetClubId
    };
    dispatch({ type: "COURSE_ADD", payload: newCourse });
    setActiveTab(state.courses.length + 2);
    setUrlParams({});
  }

  function isAddCourseDisabled() {
    // Club must be created before setting up courses
    if (!slug) return true;
    const { courses } = state;
    if (courses && courses.length) {
      const newCourses = courses.filter((c) => c._id === undefined);
      return newCourses && newCourses.length === 1;
    }
    return false;
  }

  // Middleware for form validation
  const handleSaveForm = (event, errors) => {
    if (errors.length)
      return addAlert({
        color: "danger",
        message: `Please fill out all required fields: (${errors?.length} remaining).`
      });
    handleSave();
  };

  function handleSave() {
    const clubId = state._id;
    let input = { ...state };

    // only save id
    input.accountManager = input.accountManager
      ? input.accountManager._id
      : null;

    // save separately
    delete input.courses;
    delete input.comments;

    // not part of schema
    delete input._id;

    if (slug) updateClub({ variables: { input, clubId } });
    else createClub({ variables: { clubInput: input, courses: [] } });
  }

  function handleSaveCompleted(data) {
    if (data.ok) {
      // Update URL on new club created
      if (slug !== data.club.slug)
        props.history.replace(`/club/${data.club.slug}`);

      setEdited(false);
      dispatch({ type: "INIT", payload: data.club });
      addAlert({ color: "success", message: "Club successfully updated" });
    } else {
      addAlert({ color: "danger", message: "Failed to update club" });
    }
  }

  function handleSaveError(data) {
    addAlert({ color: "danger", message: "Failed to update club" });
  }

  function middleware(event) {
    if (!edited) setEdited(true);
    dispatch(event);
  }

  function redirectWarning() {
    if (edited) return "This will discard all unsaved edits. Are you sure?";
  }

  function handleNavLinkClick(index) {
    const newUrlParams = {};
    if (index !== 1) newUrlParams.course = state.courses[index - 2].slug;
    setActiveTab(index);
    setUrlParams(newUrlParams);
  }

  // shortcut
  const { slug } = props.match.params;

  // contexts
  const { addAlert } = useContext(LayoutContext);

  // reducer
  const [state, dispatch] = useReducer(clubReducer, null);

  // state
  const history = useHistory();
  const [urlParams, setUrlParams] = useState(
    URLHelper.queryToState(history.location)
  );
  const [edited, setEdited] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  // queries, mutations
  const [getClub, { loading, error }] = useLazyQuery(CLUB_GET_BY_SLUG, {
    variables: { slug },
    onCompleted: init
  });
  const [updateClub, { loading: updateClubLoading }] = useMutation(
    CLUB_UPDATE,
    {
      onCompleted: (data) => handleSaveCompleted(data.updateClub),
      onError: handleSaveError
    }
  );
  const [createClub, { loading: createClubLoading }] = useMutation(
    CLUB_CREATE,
    {
      onCompleted: (data) => handleSaveCompleted(data.createClub),
      onError: handleSaveError
    }
  );
  const mutationLoading = updateClubLoading || createClubLoading;

  useEffect(() => {
    window.onbeforeunload = redirectWarning;
    if (slug) getClub();
    else init(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    history.replace({ search: URLHelper.queryFromState(urlParams) });
  }, [urlParams, history]);

  if (loading) return <Loader fullscreen={true} />;
  if (error) return <NoMatch />;

  if (!state) return null;

  return (
    <ClubContext.Provider
      value={{ dispatch: middleware, state, silentDispatch: dispatch }}
    >
      <div className="Club page">
        {mutationLoading && <Loader fullscreen />}
        <h1 className={state._id ? "mb-0" : ""}>{state.name || ""} </h1>
        {state._id && <p className="text-muted mb-4">Club ID: {state._id}</p>}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleNavLinkClick(1)}
            >
              Club Info
            </NavLink>
          </NavItem>
          {state.courses.map((course, index) => (
            <NavItem key={index}>
              <NavLink
                className={activeTab === index + 2 ? "active" : ""}
                onClick={() => handleNavLinkClick(index + 2)}
              >
                {course.name}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <Button
              color="link"
              onClick={addCourse}
              disabled={isAddCourseDisabled()}
            >
              + Add course
            </Button>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <Card>
              <CardBody className="px-4">
                <AvForm onSubmit={handleSaveForm}>
                  <ClubGeneralInfo />
                  <ClubProviderDetails />
                  <h3 className="mt-4">Contact info</h3>
                  <ContactInfo context={ClubContext} />
                  <ClubContactPersonList contacts={state.contacts} />
                  <Button
                    id="btn-save"
                    className="btn-lg mt-4 float-right"
                    type="submit"
                    color={edited ? "secondary" : "gray"}
                    disabled={!edited}
                  >
                    Save
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </TabPane>

          {state.courses.map((course, index) => (
            <TabPane tabId={index + 2} key={index}>
              <Card>
                <CardBody>
                  <ClubCourse
                    clubId={state._id}
                    teeSheetProvider={state.teeSheetProvider}
                    course={course}
                  />
                </CardBody>
              </Card>
            </TabPane>
          ))}
        </TabContent>
        {slug && <ClubComments comments={state.comments} />}
      </div>
    </ClubContext.Provider>
  );
}

export default Club;
