// Vendor
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Input, Form, ButtonGroup, Button, Label, Row, Col } from "reactstrap";

// App
import CountryCollection from "../../common/CountryCollection";
import ProviderCollection from "../../common/ProviderCollection";
import useDebounce from "../../common/useDebounce";

function ClubSearchInputForm({ handleSubmit }) {
  const [nameFilter, setNameFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const [amadeusActive, setAmadeusActive] = useState(null);
  const [providerFilter, setProviderFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);

  const debouncedNameFilter = useDebounce(nameFilter, 750);
  const debouncedProviderFilter = useDebounce(providerFilter, 750);
  const debouncedCountryFilter = useDebounce(countryFilter, 750);

  useEffect(() => {
    submitSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedNameFilter,
    debouncedProviderFilter,
    debouncedCountryFilter,
    activeFilter,
    amadeusActive
  ]);

  // Handlers
  function handleFilterChange(value) {
    setActiveFilter((prev) => (prev === value ? null : value));
  }
  function handleAmadeusActiveChange(value) {
    setAmadeusActive((prev) => (prev === value ? null : value));
  }

  function submitSearch() {
    const filter = {
      name: nameFilter,
      provider: providerFilter.map((p) => p.value),
      country: countryFilter.map((c) => c.label)
    };
    if (activeFilter !== null) filter["active"] = activeFilter;
    if (amadeusActive !== null) filter["amadeusActive"] = amadeusActive;
    handleSubmit(filter);
  }

  const FilterButtons = ({ title, id, handleFilterChange, active }) => {
    return (
      <div className="d-inline-block mr-2 mb-2">
        <Label for={id}>{title}</Label>
        <ButtonGroup id={id}>
          <Button
            color={`${active === true ? "primary" : "outline-light"}`}
            onClick={() => handleFilterChange(true)}
          >
            Yes
          </Button>
          <Button
            color={`${active === false ? "primary" : "outline-light"}`}
            onClick={() => handleFilterChange(false)}
          >
            No
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        submitSearch();
      }}
    >
      <Row>
        <Col>
          <label htmlFor="nameLike">Club/course name</label>
          <Input
            placeholder="Club name"
            id="nameLike"
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </Col>
        <Col>
          <label htmlFor="providers">Providers</label>
          <Select
            id="providers"
            options={ProviderCollection.map((p) => ({
              label: p.label,
              value: p.key
            }))}
            value={providerFilter}
            onChange={(val) => setProviderFilter(val ?? [])}
            isMulti
          />
        </Col>
        <Col>
          <label htmlFor="countries">Countries</label>
          <Select
            id="countries"
            options={CountryCollection.map((c) => ({
              label: c.name,
              value: c.code
            }))}
            value={countryFilter}
            onChange={(val) => setCountryFilter(val ?? [])}
            isMulti
          />
        </Col>
        <Col>
          <FilterButtons
            id="btn-group-active-1"
            title="Claimed"
            handleFilterChange={handleFilterChange}
            active={activeFilter}
          />
          <FilterButtons
            id="btn-group-active-2"
            title="Amadeus Active"
            handleFilterChange={handleAmadeusActiveChange}
            active={amadeusActive}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default ClubSearchInputForm;
