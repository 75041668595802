// Vendor
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

// App
import { CLUB_SEARCH, CLUB_SEARCH_PAGE } from "../../common/Queries";
import ClubSearchInputForm from "./ClubSearchInputForm";
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";
import { ClubSearchTable } from "./ClubSearchTable";
import { LayoutContext } from "../app/Layout";

export function ClubSearch() {
  const { addAlert } = useContext(LayoutContext);

  // State hooks
  const [filter, setFilter] = useState({});
  const [sorting, setSorting] = useState({
    sortOn: "LAST_UPDATED",
    ascending: false
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [matches, setMatches] = useState({ matchCount: 0, pageCount: 1 });

  // Queries
  const [getClubList, { loading: clubListLoading, error, data }] = useLazyQuery(
    CLUB_SEARCH_PAGE
  );
  const [getPageCount, { loading: pageCountLoading }] = useLazyQuery(
    CLUB_SEARCH,
    {
      onCompleted({ clubSearch }) {
        setMatches({ ...clubSearch });
      }
    }
  );

  // Effect hooks
  useEffect(() => {
    getClubList({
      variables: {
        index: pageIndex,
        sorting,
        filter
      }
    });
  }, [getClubList, pageIndex, sorting, filter]);

  useEffect(() => {
    getPageCount({ variables: { filter } });
    setPageIndex(0);
  }, [getPageCount, filter]);

  // Handlers
  function handleSortChange(sortOn) {
    if (sorting.sortOn === sortOn) {
      setSorting({ sortOn, ascending: !sorting.ascending });
    } else {
      setSorting({ sortOn, ascending: false });
    }
  }

  function handlePageNavClick(newIndex) {
    newIndex = Number(newIndex);
    if (newIndex > 499) {
      addAlert({
        color: "warning",
        message:
          "Page limited to 500. Result set too large, reduce set through search parameters"
      });
      newIndex = 499;
    }
    setPageIndex(newIndex);
  }

  return (
    <div className="page">
      <h1 className="mb-4">Clubs</h1>

      <ClubSearchInputForm handleSubmit={newFilter => setFilter(newFilter)} />

      <div className="text-black-50">Matches: {matches.matchCount}</div>

      <ClubSearchTable
        sorting={sorting}
        onSortingChange={handleSortChange}
        loading={!data || clubListLoading || pageCountLoading}
        error={error}
        data={data}
      />

      <CondensedNavButtonBar
        value={pageIndex}
        onBtnClick={handlePageNavClick}
        pageCount={matches.pageCount}
      />

      <div className="Save-container">
        <div className="Save-container__phantom" />
        <div className="Save-container__main">
          <Link
            to="/club/"
            className="btn btn-secondary mt-2 mb-2"
            id="btn-club-create"
          >
            New club
          </Link>
        </div>
      </div>
    </div>
  );
}
